import HelperService from '@/services/HelperService';
import axios from '@/libs/axios';

const baseUrl = '/admin/purple-docs/template-categories'

export default {
  listCategories(filters) {
    const queryString = HelperService.buildSearchQueryString(filters)
    return axios.get(`${baseUrl}${queryString}`)
  },
  createCategory(category) {
    return axios.post(`${baseUrl}`, category)
  },
  deleteCategory(id) {
    return axios.delete(`${baseUrl}/${id}`)
  },
  updateCategory(id, category) {
    return axios.put(`${baseUrl}/${id}`, category)
  }
}
