<style lang="scss">

</style>
<template>
  <div class="purple-docs-template-categories">
    <b-card title="Manage Template Categories">
      <b-btn
        variant="success"
        class="float-right" style="margin-top: -10px; margin-right: 28px;"
        @click="$bvModal.show('modal-create-category');"
      >
        Add New Category
      </b-btn>
      <b-card-text>
        This page shows all Purple Docs template categories.
      </b-card-text>
    </b-card>
    <b-card no-body class="mb-0">

      <section>
        <b-table
          class="data-table"
          hover
          :items="templateCategories"
          :fields="headers"
        >

          <template #cell(name)="data">
            {{ data.item.name }}
          </template>

          <template #cell(actions)="data">
            <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v" />
              </template>
              <b-dropdown-item-button class="full-nested-btn"
                                      @click="showCategoryModal(data.item, 'modal-update-category')"
              >
                Update
              </b-dropdown-item-button>
              <b-dropdown-item-button class="full-nested-btn"
                                      @click="showCategoryModal(data.item, 'modal-delete-category')"
              >
                Delete
              </b-dropdown-item-button>
            </b-dropdown>
          </template>

        </b-table>
        <b-row>
          <p v-if="!templateCategories.length" class="col-6 ml-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-create-category" title="Add a new category" hide-footer>
      <CreateCategoryModal @close="closeModals" @refresh="refreshCategories" />
    </b-modal>

    <b-modal id="modal-delete-category" title="Delete a category" hide-footer>
      <delete-modal
        v-if="selectedCategory"
        :subtitle="selectedCategory.name"
        title="Are you sure you wish to delete this category? This will also delete all associated templates."
        @close="refreshCategories()"
        @delete="deleteTemplateCategory(selectedCategory.id)"
      />
    </b-modal>

    <b-modal id="modal-update-category" title="Update a category" hide-footer>
      <UpdateCategoryModal
        v-if="selectedCategory"
        :category="selectedCategory"
        @close="closeModals"
        @refresh="refreshCategories"
      />
    </b-modal>

  </div>
</template>

<script>
import 'vue-search-select/dist/VueSearchSelect.css';
import PurpleDocsCategoryService from "@/services/PurpleDocsCategoryService";
import helperService from "@/services/HelperService";
import CreateCategoryModal from "@/views/purpleDocs/sections/CreateCategoryModal.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import UpdateCategoryModal from "@/views/purpleDocs/sections/UpdateCategoryModal.vue";

export default {
  name: 'PurpleDocsTemplateCategories',
    components: {DeleteModal, CreateCategoryModal, UpdateCategoryModal},
  props: {},
  data() {
    return {
      headers: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      templateCategories: [],
      selectedCategory: null,
      filters: {},
    };
  },
  mounted() {
    this.getTemplateCategories();
  },
  methods: {
    async getTemplateCategories() {
      try {
        const { data } = await PurpleDocsCategoryService.listCategories(this.filters)
        this.templateCategories = data.data
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not fetch categories, please refresh and try again')
      }
    },
    async deleteTemplateCategory(id) {
        try {
            await PurpleDocsCategoryService.deleteCategory(id)
            helperService.showNotfySuccess(this.$toast, `The category has been successfully deleted.`)
        } catch(err) {
            helperService.showNotfyErr(this.$toast, err, 'An error occurred while deleting the category. Please try again.')
        } finally {
            this.refreshCategories()
        }
    },
    closeModals() {
      this.$bvModal.hide('modal-create-category');
      this.$bvModal.hide('modal-update-category');
      this.$bvModal.hide('modal-delete-category');
    },
    showCategoryModal(category, modalName) {
      this.selectedCategory = category
      this.$bvModal.show(modalName);
    },
    refreshCategories() {
      this.closeModals()
      this.getTemplateCategories()
    }
  },
};
</script>
