<template>
  <div class="create-template-category-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(createCategory)">
        <b-form-group>
          <!-- Set Name -->
          <b-input-group class="mb-2 d-flex flex-column">
            <div>
              <label>Name</label>
              <validation-provider
                v-slot="validationContext"
                name="category name"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="templateCategory.name"
                  placeholder="Enter the category name..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </b-input-group>

          <b-input-group class="d-flex flex-row-reverse">
            <b-btn variant="primary" type="submit" :disabled="invalid || submitting">Create Category</b-btn>
            <b-btn class="d-inline mr-2" @click="$emit('close')">Cancel</b-btn>
          </b-input-group>
        </b-form-group>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import helperService from '@/services/HelperService';
import purpleDocsCategoryService from "@/services/PurpleDocsCategoryService";

export default {
  name: 'CreateTemplateCategory',
  data() {
    return {
      submitting: false,
      templateCategory: {
        name: '',
      },
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async createCategory() {
      this.submitting = true
      try {
        await purpleDocsCategoryService.createCategory(this.templateCategory)
        helperService.showNotfySuccess(this.$toast, 'The category has been created successfully.')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while creating the category. Please refresh and try again.')
      } finally {
        this.submitting = false
        this.$emit('refresh')
      }
    },
  },
}
</script>

<style scoped>

</style>
