<template>
  <div class="update-template-category-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(updateCategory)">
        <b-form-group>
          <!-- Set Name -->
          <b-input-group class="mb-2 d-flex flex-column">
            <div>
              <label>Name</label>
              <validation-provider
                v-slot="validationContext"
                name="category name"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="updatedCategory.name"
                  placeholder="Enter a new category name..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </b-input-group>

          <!-- Set Icon -->
          <b-input-group class="mb-2 d-flex flex-column">
            <div>
              <label>Icon</label>
              <b-input-group-append>
                <model-list-select v-model="updatedCategory.icon"
                                   :list="iconOptions"
                                   option-value="icon"
                                   option-text="icon"
                                   placeholder="Select icon"
                />
              </b-input-group-append>
            </div>
          </b-input-group>

          <b-input-group class="d-flex flex-row-reverse">
            <b-btn variant="primary" type="submit" :disabled="invalid || submitting">Update Category</b-btn>
            <b-btn class="d-inline mr-2" @click="$emit('close')">Cancel</b-btn>
          </b-input-group>
        </b-form-group>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select';
import helperService from '@/services/HelperService';
import purpleDocsCategoryService from "@/services/PurpleDocsCategoryService";

export default {
  name: 'UpdateTemplateCategory',
  components: { ModelListSelect },
  props: {
    category: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      submitting: false,
      updatedCategory: {
        name: '',
        icon: '',
      },
      iconOptions: [
        'tabler-mail',
        'tabler-award',
        'tabler-file-text',
        'tabler-heart',
        'tabler-calendar',
        'tabler-coin',
        'tabler-file',
        'tabler-folder',
        'tabler-pencil',
        'tabler-home',
        'tabler-flag',
        'tabler-share',
        'tabler-bookmark',
        'tabler-bell',
        'tabler-search',
        'tabler-camera',
        'tabler-map-pin',
        'tabler-tools',
        'tabler-settings',
        'tabler-moon',
        'tabler-sun',
        'tabler-wallet',
        'tabler-rocket',
        'tabler-clock',
        'tabler-link',
        'tabler-trending-up',
        'tabler-gavel',
        'tabler-users',
        'tabler-help',
        'tabler-shield-off',
        'tabler-phone-call',
        'tabler-school',
        'tabler-clipboard',
        'tabler-briefcase',
        'tabler-leaf',
        'tabler-palette',
        'tabler-compass',
        'tabler-tag',
        'tabler-fingerprint',
        'tabler-bus',
        'tabler-world',
        'tabler-user-check',
        'tabler-message-circle'
      ].map( el => ({icon: el})),
    }
  },
  mounted() {
    this.updatedCategory = { ...this.category }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async updateCategory() {
      this.submitting = true
      try {
        await purpleDocsCategoryService.updateCategory(this.category.id, this.updatedCategory)
        helperService.showNotfySuccess(this.$toast, 'The category has been updated successfully.')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while updating the category. Please refresh and try again.')
      } finally {
        this.submitting = false
        this.$emit('refresh')
      }
    },
  },
}
</script>

<style scoped>

</style>
